<template>
  <div>
    <h5 class="text-light font-weight-bold mb-3">
      {{ $t("createShipment2") }}
    </h5>
    <b-form class="form-container">
      <div class="px-3 pt-3">
        <div class="sender-container">
          <InputText
            :placeholder="$t('fullname2')"
            type="text"
            name="senderName"
            v-model="form.SenderName"
            :textFloat="$t('senderInformation')"
            :isValidate="validate.form.SenderName.$error"
            isRequired
            :v="validate.form.SenderName"
          />
          <InputText
            :placeholder="$t('telephoneNumber')"
            type="tel"
            name="senderTelephone"
            v-model="form.SenderPhone"
            textFloat=""
            :isValidate="validate.form.SenderPhone.$error"
            isRequired
            :v="validate.form.SenderPhone"
            @onKeypress="isNumber($event)"
          />
        </div>
      </div>
      <div class="p-3">
        <InputText
          :placeholder="$t('fullname2')"
          type="text"
          name="recipientName"
          v-model="form.RecipientName"
          :textFloat="$t('recipientInformation')"
          :isValidate="validate.form.RecipientName.$error"
          isRequired
          :v="validate.form.RecipientName"
        />
        <InputText
          :placeholder="$t('telephoneNumber')"
          type="tel"
          name="recipientPhone"
          v-model="form.RecipientPhone"
          textFloat=""
          :isValidate="validate.form.RecipientPhone.$error"
          isRequired
          :v="validate.form.RecipientPhone"
          @onKeypress="isNumber($event)"
        />
        <InputTextArea
          name="address"
          v-model="form.RecipientAddress"
          :placeholder="$t('recipientAddress2')"
          rows="6"
          :isValidate="validate.form.RecipientAddress.$error"
          :v="validate.form.RecipientAddress"
          textFloat=""
          noHeader
        />
      </div>
      <div class="information-container p-3">
        <InputText
          placeholder="0.00฿"
          type="number"
          name="codAmount"
          v-model.number="form.CodAmount"
          :textFloat="$t('cod2')"
          :isValidate="validate.form.CodAmount.$error"
          isRequired
          :v="validate.form.CodAmount"
          @onKeypress="isNumber($event)"
        />
        <InputText
          :placeholder="$t('remark2')"
          type="text"
          name="remark"
          v-model="form.Remark"
          :textFloat="$t('remark3')"
        />
      </div>
      <div class="px-3">
        <b-button type="button" @click="onSubmit" class="w-100 btn-main mb-4">
          {{ $t("submit4") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputTextArea from "@/components/input/InputTextArea";
export default {
  name: "CreateShipmentForm",
  components: {
    InputText,
    InputTextArea
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    validate: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  background-color: white;
  border-radius: 14px;
  position: relative;
  padding-top: 10px;
}

.information-container {
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
}

.sender-container {
  border-bottom: 1px solid #e0e0e0;
}

// .btn-language {
//   position: absolute;
//   top: 7px;
//   right: 15px;
//   z-index: 1;
// }

.btn-language {
  background: white !important;
  color: #f37021 !important;
  border: none;
}

.position-btn-language {
  position: absolute;
  right: 20px;
  top: 20px;
}

@media only screen and (max-width: 430px) {
  .position-btn-language {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
