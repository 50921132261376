<template>
  <div>
    <div class="booking-container">
      <div class="header-container">
        <p class="mb-0 text-color-kerry font-weight-bold py-2 px-3">
          {{ result.trackignNo }}
        </p>
      </div>
      <b-container class="p-3 m-0 detail-container">
        <b-row class="no-gutters">
          <b-col cols="4">
            <span>{{ $t("recipient") }}</span></b-col
          >
          <b-col cols="8"
            ><span>{{ result.RecipientName }}</span>
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col cols="4">
            <span>{{ $t("telephone") }} </span></b-col
          >
          <b-col cols="8"
            ><span>{{ result.RecipientPhone }}</span>
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col cols="4">
            <span>{{ $t("deliveryAddress") }}</span></b-col
          >
          <b-col cols="8"
            ><span>{{ result.RecipientAddress }}</span>
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col cols="4">
            <span>{{ $t("bookingDate") }} </span></b-col
          >
          <b-col cols="8"
            ><span>{{ result.createdDate }}</span>
          </b-col>
        </b-row>
      </b-container>
      <div class="p-3 text-center">
        <div class="text-center image-container">
          <img :src="result.trackignNoQRCode" class="box-img" />
        </div>
        <p>Booking No. {{ result.trackignNo }}</p>
      </div>
    </div>
    <router-link
      :to="`/my-shipment/${lang}?sessionId=${result.sessionId}&telephone=${result.telephone}&trackingNo=${result.trackignNo}`"
    >
      <p class="text-light text-center text-decoration">
        {{ $t("gotoShipmentHistory") }}
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
    name:"CreateShipmentPreview",
    props: {
        result: {
            type: Object,
            required:true
        },
        lang: {
            type: String,
            require: true
        }
    }
}
</script>

<style lang="scss" scoped>
.header-container {
  border-bottom: 1px solid #bebebe;
}

.detail-container {
  border-bottom: 1px solid #bebebe;
  max-width: 100%;
}

.image-container {
  border: 1px solid #bebebe;
}

.booking-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.text-decoration {
  text-decoration: underline;
}

.box-img {
  width: 50%;
  height: 50%;
}

@media only screen and (max-width: 576px) {
  .box-img {
    width: 100%;
    height: 100%;
  }
}
</style>