<template>
  <div class="h-100vh main-bg-color p-3">
    <div class="position-btn-language">
      <b-button class="btn-language ml-2" @click="changeLanguage">
        TH / EN
      </b-button>
    </div>
    <div class="d-flex justify-content-center">
      <div class="shipment-card">
        <div v-if="state === 2">
          <CreateShipmentPreview :result="result" :lang="lang" />
        </div>
        <div v-else>
          <CreateShipmentForm
            :form="form"
            :validate="$v"
            :onSubmit="onSubmit"
          />
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlertError
      ref="modalAlertError"
      :text="modalMessage"
      @hadleModalAlertErrorClose="hadleModalAlertErrorClose"
    />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputTextArea from "@/components/input/InputTextArea";
import { required, minValue } from "vuelidate/lib/validators";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
import CreateShipmentForm from "./components/CreateShipmentForm.vue";
import CreateShipmentPreview from "./components/CreateShipmentPreview.vue";
import * as moment from "moment/moment";
export default {
  name: "CreateShipment",
  components: {
    InputText,
    InputTextArea,
    ModalLoading,
    ModalAlert,
    ModalAlertError,
    CreateShipmentForm,
    CreateShipmentPreview
  },
  data() {
    return {
      state: 1,
      sessionId: null,
      lang: "",
      modalMessage: "",
      telephone: "",
      form: {
        SenderName: "",
        SenderPhone: "",
        RecipientName: "",
        RecipientPhone: "",
        RecipientAddress: "",
        CodAmount: 0,
        Remark: "",
        MemberToken: "",
        UserGUID: ""
      },
      result: {},
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      }
    };
  },
  validations: {
    form: {
      SenderName: { required },
      SenderPhone: { required },
      RecipientName: { required },
      RecipientPhone: { required },
      RecipientAddress: { required },
      CodAmount: { required }
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    this.lang = this.$route.params.lang ? this.$route.params.lang : "th";
    if (this.sessionId) {
      await this.getUserDetail();
    } else {
      this.$router.push("/session-expired");
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      } else {
        this.$refs.modalLoading.show();
        const url = `${this.$baseUrl}/kerryApi/CreateShipment`;
        await this.$axios.post(url, this.form).then(data => {
          if (data.data.result == 1) {
            const detail = data.data.detail;
            this.prepareResult(detail);
            this.modalMessage = "สร้างสำเร็จ";
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = data.data.detail;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
      }
    },
    prepareResult(detail) {
      this.result = this.form;
      this.result.trackignNo = detail.trackignNo;
      this.result.trackignNoQRCode = detail.trackignNoQRCode;
      const today = moment().locale("en");
      this.result.createdDate = today.format(this.$formatDate);
      this.result.telephone = this.telephone;
      this.result.sessionId = this.sessionId;
    },
    changeLanguage() {
      let getLanguage = $cookies.get("language");
      let getParams = this.$route.fullPath.split("?").slice(1);

      getLanguage = getLanguage == "th" ? "en" : "th";

      let finalUrl = `/create-shipment/${getLanguage}?${getParams}`;

      this.$router.replace(finalUrl).catch(() => {
        return;
      });
    },
    hadleModalAlertClose() {
      this.state = 2;
    },
    hadleModalAlertErrorClose() {
      this.$refs.modalAlertError.hide();
    },
    async getUserDetail() {
      const getUserUrl = `${this.$baseUrl}/kerryApi/user/${this.sessionId}`;
      const { result, error } = await this.handlePromise(
        this.$axios.get(getUserUrl)
      );
      if (error) {
        console.log(error);
        return;
      }
      if (result.data.result === 1) {
        this.form.UserGUID = result.data.detail.userGUID;
        if (!this.form.UserGUID) {
          this.$router.push("/session-expired");
        } else if (!result.data.detail.isLogin) {
          var redirectUrl = encodeURIComponent(
            `/create-shipment/${this.lang}?sessionId=${this.sessionId}`
          );
          this.$router.push(
            `/login?sessionId=${this.sessionId}&redirectUrl=${redirectUrl}`
          );
        }
        this.form.SenderName =
          result.data.detail.firstname && result.data.detail.lastname
            ? `${result.data.detail.firstname} ${result.data.detail.lastname}`
            : "";
        this.form.SenderPhone = result.data.detail.telephone;
        this.form.MemberToken = result.data.detail.memberToken;
        this.telephone = result.data.detail.telephone;
      }
    },
    handlePromise: function (promise) {
      return promise
        .then(data => {
          return {
            error: null,
            result: data
          };
        })
        .catch(err => {
          return {
            error: err
          };
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.shipment-card {
  width: 100%;
}

.btn-language {
  background: white !important;
  color: #f37021 !important;
  border: none;
}

.position-btn-language {
  position: absolute;
  right: 15px;
  top: 10px;
}

@media only screen and (min-width: 767.98px) {
  .shipment-card {
    max-width: 800px;
  }
}
</style>
